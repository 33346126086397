<template>
  <pibot-chart-pie
    v-if="data"
    :data="formattedData"
    :colorScheme="['#80bcb0', '#cccccc', '#11526c']"
    :useTooltip="true"
    :labels="labels || computedLabels"
    :width="400"
    :height="400"
    orient="right"
    donut
    show-labels
  >
    <template #title>
      Pipeline Inspection, Repair and Maintenance cost distribution
    </template>
  </pibot-chart-pie>
</template>

<script>
import optimisationMixin from '../utils/mixins/optimisation.mixin'
import styleMixin from '@/utils/mixins/styling/style.mixin'
import chartPropsMixin from '@/utils/mixins/charts/chartProps.mixin'
import chartTooltipMixin from '@/utils/mixins/charts/chartTooltip.mixin'

export default {
  name: 'pibot-optimisation-cost-distribution',
  mixins: [optimisationMixin, styleMixin, chartPropsMixin, chartTooltipMixin],
  components: {
    'pibot-chart-pie': () => import('@/components/charts/PieChart')
  },
  data: () => ({
    data: null
  }),
  computed: {
    activeData () {
      return this.data[this.activeGroup]
    },
    formattedData () {
      const returnObj = {}
      this.activeData.forEach(d => {
        Object.keys(d).forEach(k => {
          if (k !== 'year') { returnObj[k] = this.activeData.reduce((b, a) => b + a[k], 0) }
        })
      })

      return returnObj
    },
    computedLabels () {
      return ['Repair and Maintenance', 'In-line Inspection', 'Spot Inspection']
    }
  },
  mounted () {
    this.fetchIrmOptimisation().then((data) => {
      this.data = data
    }).catch(() => {})
  }
}
</script>
